@import '../../../../assets/styles/variables.scss';

.verify-phone-screen  {
  align-content: center;
  display: grid;
  gap: 48px;
  padding-bottom: 40vh;

  @media screen and (min-width: $tablet-width) {
    padding-bottom: 0;
  }
}

.verify-phone-screen__content {
  display: grid;
  gap: 20px;
}

.verify-phone-screen__text {
  color: $color-black-75;
  font-family: $font-main;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
}

.verify-phone-screen__text_small {
  font-size: 16px;
}

.verify-phone-screen__link {
  color: $color-theme-accent;
  display: inline-block;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  text-align: left;
  text-decoration: underline;
  text-transform: initial;
}
