@import '../../../../assets/styles/variables.scss';

.sign-in {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media screen and (min-width: $tablet-width) {
    justify-content: center;
    min-height: 442px;
  }
}

.sign-in__wrapper {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 32px;
}

.sign-in__form {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 16px;
}

.sign-in__reset {
  color: $color-theme-accent;
  // font-size: 12px;
  font-size: 0 !important; // TODO undo when ready
  font-style: normal;
  font-weight: normal;
  justify-self: start;
  // line-height: 160%;
  line-height: 0% !important;// TODO undo when ready
  text-align: left;
  text-decoration: underline;
  text-transform: initial;
  margin: 0;
  // padding-bottom: 16px;
  padding-bottom: 0 !important; // TODO undo when ready

  @media screen and (min-width: $tablet-width) {
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 180%;
    padding-bottom: 24px;
  }
}
