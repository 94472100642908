@import "../../../assets/styles/variables.scss";

.radio {
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  min-height: 20px;
  min-width: 20px;
  padding-left: 24px;
  position: relative;
}

.radio__control {
  align-items: center;
  background-color: $color-white;
  border: 1px solid $color-black-20;
  border-radius: 50%;
  box-sizing: inherit;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  left: 2px;
  position: absolute;
  top: calc(50% - 10px);
  transition: 0.2s linear;
  width: 20px;

  &::after {
    background-color: $color-black-75;
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.1s ease-in-out;
    width: 10px;
  }
}

.radio__input {
  cursor: pointer;
  height: 20px;
  left: 2px;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: calc(50% - 10px);
  width: 20px;
  z-index: 2;
}

.radio__input:hover + .radio__control {
  border-color: $color-black-30;
}

.radio__input:checked + .radio__control::after {
  opacity: 1;
}

.radio__input:focus + .radio__control {
  border-color: $color-black-30;
  outline: none;
  &::before {
    background-color: $color-black-75;
    content: "";
    height: 26px;
    left: -4px;
    opacity: 0.1;
    position: absolute;
    top: -4px;
    width: 26px;
    z-index: -1;
  }
}

.radio__label {
  box-sizing: inherit;
  padding-left: 8px;
}

.radio_disabled {
  cursor: default;

  .radio__input {
    cursor: default;
  }

  .radio__control {
    background-color: $color-black-5;
    cursor: default;

    &::after {
      background-color: $color-black-20;
    }
  }
}
