@import '../../../../assets/styles/variables.scss';

.search {
  align-items: center;
  background-color: $color-white;
  box-sizing: border-box;
  display: grid;
  min-height: 52px;
  width: 100vw;

  .button {
    color: $color-black-50;
    right: 18px;
  }

  .input__field {
    font-size: 15px;
  }

  @media (min-width: $laptop-width) {
    width: 318px;
  }
}
