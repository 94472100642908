@import "../../../../assets/styles/variables.scss";

.module-header {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 12px;

  @media screen and (min-width: $tablet-width) {
    gap: 20px;
  }
}

.module-header__wrapper {
  align-content: start;
  display: grid;
  gap: 25px;
}

.module-header__title {
  color: $color-black-75;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  margin: 0;

  @media screen and (min-width: $tablet-width) {
    font-size: 28px;
  }
}

.module-header__text {
  color: $color-black-75;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 170%;
  margin: 0;

  @media screen and (min-width: $tablet-width) {
    font-size: 16px;
    letter-spacing: 0.01em;
    line-height: 140%;
  }
}

.module-header_with-text {
  .module-header__wrapper {
    gap: 32px;
  }
}
