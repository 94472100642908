@import '../../../assets/styles/variables';

.custom-scrollbar {
  .ScrollbarsCustom-Wrapper {
    inset: 0 !important;
  }

  .ScrollbarsCustom-Thumb {
    opacity: 0.5;
    transition: opacity $transition-time;
  }

  .ScrollbarsCustom-Track {
    background: none !important;
    right: 2px !important;
    width: 6px !important;
    z-index: 20;
  }
}
