@import "../../../assets/styles/variables.scss";

.typeahead {
  position: relative;
}

.typeahead__dropdown {
  align-content: start;
  background: $color-white;
  border-radius: 2px;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.16);
  display: grid;
  justify-content: stretch;
  left: 0;
  position: absolute;
  right: 0;
  top: calc(100% + 2px);
  z-index: 100;
}

.typeahead__list {
  min-height: 36px;
  max-height: 220px;
  overflow: auto;
}

.typeahead__item {
  background: none;
  border: none;
  color: $color-black-75;
  cursor: pointer;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 143%;
  min-height: 36px;
  text-align: left;
  padding: 8px 12px;
  width: 100%;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $color-black-5;
    outline: none;
  }

  &:disabled {
    color: $color-black-20;
  }
}

.typeahead__item-label_bold {
  font-weight: 600;
}
