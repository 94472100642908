@import "../../assets/styles/variables.scss";

.main-page {
  position: relative;
}

.main-page__content {
  @media screen and (min-width: $tablet-width) {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    z-index: 2;
  }

  @media screen and (min-width: $desktop-width) {
    align-content: center;
    display: grid;
    gap: 134px;
    grid-auto-flow: column;
    justify-items: center;
    padding-left: 7%;
  }
}

.main-page__main-bg {
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: 0 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  @media screen and (min-width: $tablet-width) {
    background-color: $color-black-50;
  }
}

.main-page__user-module {
  position: relative;
  z-index: 2;
}
