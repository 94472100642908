@import '../../../assets/styles/variables.scss';

.multiline-input {
  display: block;
  padding-top: 4px;
  position: relative;
}

.multiline-input__container {
  background: $color-white;
  border: 1px solid $color-black-20;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 9px 0;
  transition: background-color $transition-time, border-color $transition-time,
    box-shadow $transition-time;
}

.multiline-input__container:hover {
  border-color: $color-black-50;

  .multiline-input__label {
    color: $color-black-75;
  }
}

.multiline-input__container_focused {
  border-color: $color-black-75 !important;
  box-shadow: 0 0 0 1px $color-black-75;

  .multiline-input__label {
    color: $color-black-75 !important;
  }
}

.multiline-input__container_disabled {
  background: $color-black-5;
  border-color: $color-black-5 !important;
  box-shadow: none;
  cursor: not-allowed;

  .multiline-input__input {
    background: $color-black-5;
    color: $color-black-20;
    cursor: not-allowed;
  }

  .multiline-input__label {
    background: transparent;
    color: $color-black-20 !important;
    cursor: not-allowed;
  }
}

.multiline-input__input {
  background-color: $color-white;
  border: none;
  box-sizing: border-box;
  color: $color-black-75;
  display: block;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  margin: 0;
  padding: 0 12px;
  resize: none;
  transition: background-color $transition-time, color $transition-time;
  width: 100%;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $color-white;
  }

  &::-webkit-scrollbar-thumb {
    border-left: 2px solid $color-white;
    border-right: 2px solid $color-black-10;
    box-sizing: border-box;
    cursor: pointer;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      border-color: $color-black-20;

      &:active {
        border-color: $color-black-75;
      }
    }
  }

  &:focus {
    outline: none;
  }
}

.multiline-input__label {
  background: $color-white;
  box-sizing: border-box;
  color: $color-black-50;
  cursor: text;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  left: 8px;
  margin: 0;
  max-width: calc(100% - 14px);
  overflow: hidden;
  padding: 0 4px;
  position: absolute;
  text-overflow: ellipsis;
  text-transform: uppercase;
  transition: color $transition-time, background-color $transition-time, color $transition-time,
    font-size $transition-time, line-height $transition-time, top $transition-time;
  top: 14px;
  white-space: nowrap;
  z-index: 5;
}

.multiline-input__label_raised {
  font-size: 10px;
  line-height: 120%;
  top: -1px;
}

.multiline-input__error {
  bottom: -15px;
  color: $color-red-dark;
  display: block;
  font-family: $font-main;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  position: absolute;
  right: 0;
  text-align: right;
}

.multiline-input__container_invalid {
  border-color: $color-red-light !important;

  .multiline-input__input {
    color: $color-red-dark;
  }

  .multiline-input__label,
  .multiline-input__label_raised {
    color: $color-red-dark !important;
  }

  &.multiline-input__container_focused {
    border-color: $color-red-dark !important;
    box-shadow: 0 0 0 1px $color-red-dark !important;
  }

  &:hover {
    border-color: $color-red-dark !important;

    .multiline-input__label {
      color: $color-red-dark !important;
    }
  }
}
