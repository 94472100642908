@import "variables";

.terms {
  background-color: $color-white;
  box-sizing: border-box;
  color: $color-black-75;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 1080px;
  padding: 20px;
  text-align: justify;
  width: 100%;

  @media (min-width: 768px) {
    padding: 70px 40px;
  }

  @media (min-width: 1280px) {
    font-size: 16px;
    padding: 70px 40px;
  }

  ol {
    counter-reset: item;
    list-style-type: none;
    padding-left: 20px;

    @media (min-width: 768px) {
      padding-left: 30px;
    }
  }

  ol > li {
    counter-increment: item;
    display: table;
    margin-bottom: 0.7em;
  }

  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }

  li ol > li {
    margin: 0;
  }

  li ol > li:before {
    content: counters(item, ".") " ";
  }

  h1,
  h2 {
    font-size: 2em;
    font-weight: normal;
    text-align: left;
  }

  h3 {
    font-size: 1.6em;
    text-align: left;
  }

  h4 {
    font-size: 1.3em;
    font-weight: normal;
    text-align: left;
  }

  h5 {
    font-size: 1.1em;
    text-align: left;
  }

  .text-underline {
    text-decoration: underline;
  }

  .padding-l-30 {
    padding-left: 30px;
  }

  .padding-t-40 {
    padding-top: 40px;
  }

  .padding-t-20 {
    padding-top: 20px;
  }

  .font-w400 {
    font-weight: 400;
  }

  .link-reset {
    color: inherit;
    text-decoration: none;
  }
}
