@import '../../../../assets/styles/variables.scss';

.login {
  background-color: $color-white;
  box-sizing: border-box;
  display: grid;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 24px 12px;
  width: 100vw;

  @media screen and (min-width: $tablet-width) {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba($color: $color-black, $alpha: 0.18);
    min-height: 333px;
    padding: 32px 32px 24px;
    width: 464px;
  }
}

.login_sign-up {
  @media screen and (min-width: $tablet-width) {
    padding: 32px;
  }
}
