@import '../../../assets/styles/variables.scss';

.error {
  border: 1px solid rgba($color: $color-red-dark, $alpha: .4);
  border-radius: 4px;
  color: $color-red-dark;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  padding: 8px 12px;
}

.error_bg {
  background-color: $color-red-regular;
  border: none;
}
