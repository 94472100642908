@import "../../../../assets/styles/variables.scss";

.status {
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  font-family: $font-main;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  padding: 2px 8px;
  text-align: center;
}

.status_sidebar {
  font-weight: 700;
  padding-left: 4px;
  padding-right: 4px;
}

.status_archived {
  background-color: rgba($color: $color-status-archived, $alpha: 0.08);
  color: $color-status-archived;
}

.status_canceled {
  background-color: rgba($color-status-canceled, $alpha: 0.08);
  color: $color-status-canceled;
}

.status_cremated {
  background-color: rgba($color-status-cremated, $alpha: 0.08);
  color: $color-status-cremated;
}

.status_done {
  background-color: rgba($color: $color-status-done, $alpha: 0.08);
  color: $color-status-done;
}

.status_in {
  background-color: rgba($color: $color-status-storage, $alpha: 0.08);
  color: $color-status-storage;
}

.status_new {
  background-color: rgba($color: $color-status-new, $alpha: 0.08);
  color: $color-status-new;
}

.status_paid {
  background-color: rgba($color: $color-status-paid, $alpha: 0.08);
  color: $color-status-paid;
}

.status_pending {
  background-color: rgba($color: $color-status-pending, $alpha: 0.08);
  color: $color-status-pending;
}

.status_ready {
  background-color: rgba($color: $color-status-ready, $alpha: 0.08);
  color: $color-status-ready;
}

.status_received {
  background-color: rgba($color: $color-status-received, $alpha: 0.08);
  color: $color-status-received;
}

.status_unpaid {
  background-color: rgba($color: $color-status-unpaid, $alpha: 0.08);
  color: $color-status-unpaid;
}
