@import "../../../../assets/styles/variables.scss";
@import "../../../../assets/styles/animation.scss";

.tabs {
  box-sizing: border-box;
  padding: 7px 12px 0;
  width: 100%;

  @media (min-width: $desktop-width) {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.tabs__wrapper {
  align-content: start;
  align-items: center;
  border-bottom: 1px solid $color-black-10;
  box-sizing: border-box;
  display: grid;
  gap: 40px;
  grid-auto-flow: column;
  justify-content: center;
}

.tabs__item {
  background: none;
  border: none;
  box-sizing: border-box;
  color: $color-black-75;
  font-weight: 400;
  margin: 0;
  padding: 8px 0;
  width: 90px;

  &:not(.tabs__item_active) {
    cursor: pointer;
  }
}

.tabs__item_active {
  color: $color-theme-accent;
  font-weight: 700;
  position: relative;

  &::after {
    background-color: $color-theme-accent;
    border-radius: 2px 2px 0 0;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.tabs__item-text {
  color: inherit;
  font-family: $font-main;
  font-size: 12px;
  font-weight: inherit;
  font-style: normal;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}
