@import '../../../../assets/styles/variables.scss';

.copyright {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 32px;
}

.copyright__icon {
  color: $color-black-50;
  height: 28px;
  width: 176px;
}
