@import '../../../assets/styles/variables.scss';

.cemetery-logo {
  display: grid;
}

.cemetery-logo_sidebar {
  gap: 8px;
  grid-auto-flow: column;
  justify-items: start;
  max-width: 215px;

  .cemetery-logo__img_with-text {
    height: 28px;
    width: 28px;

    @media (min-width: $laptop-width) {
      height: 40px;
      width: 40px;
    }
  }

  &:not(.cemetery-logo_separated) .cemetery-logo__img_square {
    @media (min-width: $laptop-width) {
      max-height: 160px;
      max-width: 160px;
    }
  }
}

.cemetery-logo__img_sidebar {
  height: 28px;
  max-width: 150px;
  width: auto;

  @media (min-width: $laptop-width) {
    height: auto;
    max-height: 80px;
    order: 0;
  }
}

.cemetery-logo__img_background {
  display: block;
  height: auto;
  max-height: 260px;
  width: 260px;

  @media screen and (max-width: ($desktop-width - 1)) {
    display: none;
  }
}

.cemetery-logo_background {
  &.cemetery-logo_separated {
    gap: 20px;

    @media screen and (max-width: ($desktop-width - 1)) {
      display: none;
    }
  }

  .cemetery-logo__img_with-text {
    display: block;
    height: auto;
    margin: 0 auto;
    width: 160px;
  }
}

.cemetery-logo_login {
  display: grid;
  justify-self: center;

  @media screen and (min-width: $desktop-width) {
    display: none;
  }

  &.cemetery-logo_separated{
    align-items: center;
    gap: 8px;
    grid-auto-flow: column;
    justify-content: center;


    @media screen and (min-width: $desktop-width) {
      display: none;
    }

    .cemetery-logo__img_with-text {
      height: 32px;
      width: 32px;
    }
  }
}

.cemetery-logo__img_login {
  height: auto;
  justify-self: center;
  max-height: 36px;
  max-width: 150px;
  width: auto;

  @media screen and (min-width: $desktop-width) {
    display: none;
  }
}
