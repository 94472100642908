//Colors
$color-black: #000000;
$color-black-90: #222222;
$color-black-75: #3b3b3b;
$color-black-50: #808080;
$color-black-30: #b9b9b9;
$color-black-20: #cdcdcd;
$color-black-10: #e5e5e5;
$color-black-5: #f7f7f7;
$color-red-dark: #d95151;
$color-red-light: #f9919a;
$color-red-regular: #fff5f6;
$color-white: #ffffff;

// Theme colors
$color-theme-accent: var(--color-theme-accent);
$color-theme-light: var(--color-theme-light);

// Statuses colors
$color-status-archived: #c174c2;
$color-status-canceled: #d8c2c2;
$color-status-cremated: #3b5f78;
$color-status-done: #4cb53b;
$color-status-new: #d58b22;
$color-status-paid: #2b9529;
$color-status-pending: #f0ad4e;
$color-status-ready: #1c63a4;
$color-status-received: #30aca5;
$color-status-storage: #67b278;
$color-status-unpaid: $color-black-30;

$color-rush: #f7cbce;

//Fonts
$font-main: "proxima-nova", "Arial", sans-serif;
$font-logo-title: "PT Sans Narrow", "Arial", sans-serif;
$font-logo-subtitle: "Mako", "Arial", sans-serif;

//Screens
$tablet-width: 768px;
$laptop-width: 1024px;
$desktop-width: 1280px;

//Transition
$transition-time: 0.2s;
$transition-time-long: 0.3s;
