@import "../../../assets/styles/variables.scss";

.checkbox {
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  min-height: 20px;
  min-width: 20px;
  padding-left: 24px;
  position: relative;
}

.checkbox__control {
  align-items: center;
  background-color: $color-white;
  border: 1px solid $color-black-20;
  border-radius: 1px;
  box-sizing: inherit;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  left: 2px;
  position: absolute;
  top: calc(50% - 10px);
  transition: 0.2s linear;
  width: 20px;

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 1L4.33333 7L1 4.00013' stroke='%233B3B3B' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    content: "";
    height: 10px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.1s ease-in-out;
    width: 12px;
  }
}

.checkbox__input {
  cursor: pointer;
  height: 20px;
  left: 2px;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: calc(50% - 10px);
  width: 20px;
  z-index: 2;
}

.checkbox__input:hover + .checkbox__control {
  border-color: $color-black-30;
}

.checkbox__input:checked:not(:disabled) + .checkbox__control::after {
  opacity: 1;
}

.checkbox__input:focus + .checkbox__control {
  border-color: $color-black-30;
  outline: none;
  &::before {
    background-color: $color-black-75;
    content: "";
    height: 26px;
    left: -4px;
    opacity: 0.1;
    position: absolute;
    top: -4px;
    width: 26px;
    z-index: -1;
  }
}

.checkbox__label {
  box-sizing: inherit;
  padding-left: 8px;
}

.checkbox_disabled {
  cursor: default;

  .checkbox__control {
    background-color: $color-black-5;
    cursor: default;

    &::after {
      opacity: 0.3;
    }
  }

  .checkbox__input {
    cursor: default;
  }
}
