@import "../../../../assets/styles/variables.scss";

.sign-up {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media screen and (min-width: $tablet-width) {
    justify-content: center;
    min-height: 400px;
  }
}

.sign-up_verifying {
  @media screen and (min-width: $tablet-width) {
    min-height: auto;
  }
}

.sign-up__wrapper {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 26px;

  @media screen and (min-width: $tablet-width) {
    gap: 30px;
  }
}

.sign-up__form {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 24px;
}

.sign-up__inputs-block,
.sign-up__buttons {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 16px;
}

.sign-up__inputs-block {
  @media screen and (min-width: $tablet-width) {
    row-gap: 18px;
  }
}

.sign-up__inputs-block_contacts {
  @media screen and (min-width: $tablet-width) {
    grid-template-columns: 1fr 1fr;
  }
}

.sign-up__inputs-block_personal {
  @media screen and (min-width: $tablet-width) {
    grid-template-areas:
      "name family"
      "home home"
      "number number";

    & > .input:nth-child(1) {
      grid-area: name;
    }

    & > .input:nth-child(2) {
      grid-area: family;
    }

    & > .input:nth-child(3) {
      grid-area: home;
    }

    & > .input:nth-child(4) {
      grid-area: number;
    }
  }
}

.sign-up__inputs-block_error {
  .input__field {
    border-color: $color-red-dark;
  }

  .input__label {
    color: $color-red-dark;
  }
}

.sign-up__link {
  color: $color-theme-accent;
  font-family: $font-main;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 133%;

  @media screen and (min-width: $tablet-width) {
    font-size: 16px;
  }
}

.sign-up__checkbox {
  color: $color-black-75;
  font-family: $font-main;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 133%;
  padding-bottom: 5px;
  padding-top: 5px;

  @media screen and (min-width: $tablet-width) {
    font-size: 16px;
    padding-bottom: 2px;
    padding-top: 2px;
  }
}
