@import '../../../assets/styles/variables.scss';

.button {
  align-items: center;
  background: none;
  border: none;
  box-sizing: border-box;
  color: $color-black-75;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: color $transition-time linear,
    width $transition-time linear,
    background-color $transition-time linear,
    border-color $transition-time linear;

  &:disabled, &.disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: auto;
  }
}

.button__text {
  box-sizing: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  position: relative;
  text-align: inherit;
  text-transform: inherit;
  width: 100%;
}

.button_rectangle {
  border-radius: 2px;
  padding: 6px 12px;

  @media screen and (min-width: $tablet-width) {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

.button_border {
  border: 1px solid $color-black-20;
  padding-bottom: 5px;
  padding-top: 5px;

  &:hover,
  &:focus {
    border-color: $color-black-75;
    outline: none;
  }

  @media screen and (min-width: $tablet-width) {
    padding-bottom: 9px;
    padding-top: 9px;
  }
}

.button_bg {
  background-color: $color-theme-accent;
  border: none;
  color: $color-black-5;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $color-theme-light;
  }

  &:disabled,
  &.disabled {
    background-color: $color-black-5;
    color: $color-black-20;
  }
}
