@import '../../../../assets/styles/variables.scss';

.table-sidebar {
  background-color: $color-white;
  box-sizing: border-box;
  padding-top: 53px;
  width: 100%;

  @media (min-width: $laptop-width) {
    height: 100vh;
    left: 0;
    padding-top: 0;
    position: relative;
    width: 250px;

    &::before {
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.06) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      content: '';
      height: 100%;
      position: absolute;
      right: -8px;
      top: 0;
      width: 8px;
      z-index: 10;
    }
  }
}

.table-sidebar__header {
  background-color: $color-white;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  @media (min-width: $laptop-width) {
    padding: 0 20px;
    position: static;
  }
}

.table-sidebar__header-wrapper {
  align-items: center;
  border-bottom: 1px solid $color-black-10;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 12px;

  @media (min-width: $laptop-width) {
    gap: 40px;
    display: grid;
    justify-content: stretch;
    justify-items: center;
    padding: 40px 0 20px;
  }
}

.table-sidebar__header-wrapper_bigger-logo {
  @media (min-width: $laptop-width) {
    gap: 20px;
    padding-top: 30px;
  }
}

.table-sidebar__header-wrapper_square-spaced {
  @media (min-width: $laptop-width) {
    gap: 8px;
    padding-top: 30px;
  }
}

.table-sidebar__header-filters-toggle {
  color: $color-theme-accent;
  height: 20px;
  width: 20px;

  @media (min-width: $laptop-width) {
    display: none;
  }
}

.table-sidebar__link-wrapper {
  background-color: $color-white;
  bottom: 0;
  box-sizing: border-box;
  padding: 16px;
  position: fixed;
  width: 100%;
  z-index: 10;

  @media (min-width: $laptop-width) {
    display: flex;
    justify-content: center;
    padding: 32px 0 0;
    position: static;
  }
}

.table-sidebar__new-arrangement-button {
  width: 100%;
  @media (min-width: $laptop-width) {
    width: 210px;
  }
}
