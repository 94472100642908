@import "../../../assets/styles/variables.scss";
@import "../../../assets/styles/animation.scss";

.popup {
  align-items: center;
  background-color: rgba($color: $color-white, $alpha: 0.5);
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity: 0;
  padding-bottom: 100px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;

  @media (min-width: $desktop-width) {
    padding-bottom: 0;
  }
}

.popup__wrapper {
  align-content: start;
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  display: grid;
  gap: 28px;
  justify-items: center;
  max-width: 464px;
  padding: 24px 12px 12px;
  width: 100%;

  @media (min-width: $desktop-width) {
    padding: 32px;
  }
}

.popup_cancel,
.popup_info,
.popup_delete {
  animation: $transition-time-long linear forwards fadeOut;
  opacity: 0;

  .popup__wrapper {
    animation: $transition-time-long linear forwards showFromTop;
  }
}

.popup_hiding {
  animation: $transition-time-long linear reverse forwards fadeIn;
  .popup__wrapper {
    animation: $transition-time-long linear reverse forwards showFromTop;
  }
}

.popup__close-button {
  height: 20px;
  justify-self: end;
  width: 20px;
}

.popup__texts {
  align-content: start;
  display: grid;
  gap: 8px;
}

.popup__text {
  color: $color-black-75;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin: 0;
  text-align: center;

  @media (min-width: $desktop-width) {
    font-size: 18px;
  }
}

.popup__buttons {
  align-content: start;
  display: grid;
  gap: 16px;
  width: 100%;
}

.popup__button {
  width: 100%;
}

.popup__term-list {
  align-content: start;
  display: grid;
  gap: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.popup__term-item {
  align-content: start;
  display: grid;
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.popup__term-title {
  color: $color-black-75;
  font-family: $font-main;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin: 0;
  padding: 0;
}

.popup__term-description {
  color: $color-black-75;
  font-family: $font-main;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin: 0;
  padding: 0;
}

.popup_info {
  overflow: auto;
  @media (max-width: 464px) {
    padding: 0;
    overflow: auto;
  }

  @media (max-height: 600px) {
    padding-bottom: 0;
  }

  .popup__wrapper {
    gap: 8px;

    @media (max-width: 464px) {
      min-height: 100%;
      overflow: auto;
    }
  }
}

.popup_delete {
  .popup__wrapper {
    gap: 40px;
    padding-top: 40px;
  }
  .popup__text {
    @media (min-width: $desktop-width) {
      font-size: 20px;
      font-weight: 300;
    }
  }

  .popup__buttons {
    gap: 20px;
  }

  .popup__button_submit {
    color: $color-theme-accent;
  }
}
