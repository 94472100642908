@import '../../../assets/styles/variables.scss';

.logo-text {
  display: grid;
  margin: 0;
  padding: 0;
}

.logo-text__title {
  font-family: $font-logo-title;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1em;
  word-break: break-word;
}

.logo-text__subtitle {
  font-family: $font-logo-subtitle;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2em;
  word-break: break-word;
}

.logo-text_sidebar {
  align-content: center;
  color: $color-theme-accent;
  gap: 2px;
  justify-items: start;

  .logo-text__title {
    font-size: 14px;
  }

  .logo-text__subtitle {
    font-size: 8px;
  }
}

.logo-text_background {
  color: $color-black-5;
  gap: 11px;
  justify-items: center;
  max-width: 490px;
  text-align: center;

  .logo-text__title {
    font-size: 48px;
  }

  .logo-text__subtitle {
    font-size: 24px;
  }
}

.logo-text_login {
  color: $color-theme-accent;
  gap: 2px;
  justify-items: start;

  .logo-text__title {
    font-size: 14px;
  }

  .logo-text__subtitle {
    font-size: 8px;
  }
}
