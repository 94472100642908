@import "../../../assets/styles/variables.scss";

.chip {
  align-items: center;
  background-color: transparent;
  border: 1px solid $color-black-10;
  border-radius: 16px;
  box-sizing: border-box;
  color: $color-black-75;
  cursor: pointer;
  display: flex;
  font-family: $font-main;
  font-size: 12px;
  font-style: normal;
  line-height: 120%;
  min-height: 24px;
  padding: 4px 8px;
  position: relative;
  transition: background-color $transition-time ease;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: auto;
  }
}

.chip_active {
  background-color: $color-theme-accent;
  border-color: $color-theme-accent;
  color: $color-black-5;
}
