@import "../../../assets/styles/variables.scss";

.input {
  display: block;
  max-width: 100%;
  position: relative;
}

.input__label {
  background: $color-white;
  box-sizing: border-box;
  color: $color-black-50;
  cursor: text;
  display: block;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  left: 7px;
  line-height: 20px;
  max-width: calc(100% - 14px);
  overflow: hidden;
  padding: 0 4px;
  position: absolute;
  text-overflow: ellipsis;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);
  transition: color $transition-time, box-shadow $transition-time,
    top $transition-time, font-size $transition-time,
    line-height $transition-time;
  white-space: nowrap;
  width: 100%;
  z-index: 2;
}

.input__label_raised {
  font-size: 10px;
  line-height: 12px;
  top: -6px;
  transform: translateY(0);
  width: auto;
}

.input__field {
  background-color: $color-white;
  border: 1px solid $color-black-20;
  border-radius: 2px;
  box-shadow: inset 0 0 0 50px $color-white;
  box-sizing: border-box;
  color: $color-black-75;
  display: block;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 8px 10px;
  text-overflow: ellipsis;
  transition: all $transition-time linear;
  width: 100%;
  -webkit-appearance: none;
  -webkit-box-shadow: inset 0 0 0 50px $color-white;

  &:hover,
  &:focus {
    border-color: $color-black-75;
    outline: none;
  }

  &::placeholder {
    color: $color-black-50;
    font-size: inherit;
    text-transform: uppercase;
    -webkit-text-fill-color: $color-black-50;
  }

  @media (min-width: $tablet-width) {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

.input_error {
  .input__label {
    color: $color-red-light;
  }
  .input__field {
    border-color: $color-red-light;
    color: $color-red-dark;
    -webkit-text-fill-color: $color-red-dark;
  }

  &:hover {
    .input__label {
      color: $color-red-dark;
    }
    .input__field {
      border-color: $color-red-dark;
    }
  }
}

.input__error-msg {
  bottom: -18px;
  color: $color-red-light;
  font-family: $font-main;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 160%;
  position: absolute;
  right: 0;
}

.input_disabled {
  .input__field,
  .input__field:hover {
    border-color: $color-black-5;
    box-shadow: inset 0 0 0 50px $color-black-5;
    color: $color-black-20;
  }

  .input__field::placeholder {
    color: $color-black-20;
    -webkit-text-fill-color: $color-black-20;
  }

  .input__label {
    background: transparent;
    color: $color-black-20;
    cursor: default;
  }
}

.input_select {
  .input__field,
  .input__label {
    cursor: pointer;
  }
}

.input_with-icon {
  .input__field {
    padding-right: 40px;
  }

  .input__label:not(.input__label_raised) {
    width: calc(100% - 40px);
  }
}

.input_no-border {
  .input__field {
    border: none;
  }
}

.input__icon-button {
  color: $color-black-20;
  height: 16px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
}

.input__icon-button_big {
  height: 20px;
  width: 20px;
}

.input_flattened {
  .input__field {
    padding-bottom: 8px;
    padding-top: 8px;
  }
}
