@import '../../../assets/styles/variables.scss';

.select {
  position: relative;
}

.select__toggle {
  color: $color-black-75;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform $transition-time ease;
}

.select__toggle_reversed {
  transform: translateY(-50%) rotate(180deg);
}

.select_disabled {
  .select__toggle {
    color: $color-black-20;
    cursor: auto;
  }
}

.select__dropdown {
  align-content: start;
  background: $color-white;
  border-radius: 2px;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.16);
  display: grid;
  justify-content: stretch;
  left: 0;
  position: absolute;
  right: 0;
  top: calc(100% + 2px);
  z-index: 100;
}

.select__list {
  min-height: 36px;
  max-height: 220px;
  overflow: auto;
}

.select__item {
  background: none;
  border: none;
  color: $color-black-75;
  cursor: pointer;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 143%;
  min-height: 36px;
  text-align: left;
  padding: 8px 12px;
  width: 100%;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $color-black-5;
    outline: none;
  }

  &:disabled {
    color: $color-black-20;
  }
}

.select__item-label_bold {
  font-weight: 600;
}

.select__search-input {
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;

  .input__field {
    border: none;
    border-bottom: 1px solid $color-black-10;
    cursor: text;
    padding-left: 5px;
    padding-right: 50px;
  }

  .input__field::placeholder {
    color: $color-black-20;
    -webkit-text-fill-color: $color-black-20;
  }
}

.select__search-input-icon {
  color: $color-black-20;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  transition: color $transition-time ease;
  width: 20px;
}

.select__search-input-button {
  color: $color-black-50;
  height: 16px;
  position: absolute;
  right: 42px;
  top: 10px;
  width: 16px;
}

.select__search-input_filled {
  .select__search-input-icon {
    color: $color-black-75;
  }
}
