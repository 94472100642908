@import "../../../assets/styles/variables.scss";

.data-list {
  box-sizing: border-box;
  display: grid;
  gap: 8px;
  grid-template-columns: 140px 1fr;
  margin: 0;
}

.data-list__key,
.data-list__value {
  color: $color-black-75;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 143%;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data-list__value {
  font-weight: 400;
  text-align: right;
}

.data-list_align-left {
  .data-list__value {
    text-align: left;
  }
}

.data-list_align-left-desktop {
  @media (min-width: $desktop-width) {
    gap: 14px 56px;

    .data-list__value {
      text-align: left;
    }
  }
}

.data-list_bg {
  background-color: $color-black-5;
  column-gap: 12px;
  padding: 8px 16px 4px;

  .data-list__key {
    font-weight: 300;
  }

  .data-list__value {
    font-weight: 600;
  }
}

.data-list_desktop-row {
  .data-list__key,
  .data-list__value {
    line-height: 170%;
  }

  @media (min-width: $desktop-width) {
    grid-auto-flow: column;
    grid-template-columns: auto auto minmax(100px, min-content) auto auto auto;
    grid-template-rows: auto auto;
    justify-content: space-between;

    .data-list__key,
    .data-list__value {
      line-height: 143%;
      text-align: left;
    }
  }
}

.data-list__value_bold {
  font-weight: 600;
}
