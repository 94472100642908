@import "../../../../assets/styles/variables.scss";
@import "../../../../assets/styles/animation.scss";

.arrangement-sidebar {
  box-sizing: border-box;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}

.arrangement-sidebar__shadow {
  animation: $transition-time-long linear forwards fadeOut;
  background-color: rgba($color: $color-white, $alpha: 0.5);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.arrangement-sidebar__content {
  animation: $transition-time-long linear forwards showFromRight;
  background-color: $color-white;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(100%);
  width: 100vw;
  z-index: 100;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: $desktop-width) {
    padding-left: 8px;
    overflow: visible;
    width: 736px;

    &::before {
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.05) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 8px;
      z-index: 10;
    }
  }
}

.arrangement-sidebar__top {
  background-color: $color-white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.arrangement-sidebar_hide {
  .arrangement-sidebar__content {
    animation: $transition-time-long linear forwards showToRight;
    transform: translateX(0);
  }

  .arrangement-sidebar__shadow {
    animation: $transition-time-long linear forwards fadeIn;
  }
}

.arrangement-sidebar__content-wrapper {
  box-sizing: border-box;
  min-height: 100%;
}

.arrangement-sidebar__header {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  grid-template-columns: auto 1fr auto;
  padding: 16px 12px;

  @media (min-width: $desktop-width) {
    gap: 24px;
    padding: 22px 32px;
  }
}

.arrangement-sidebar__title {
  color: $color-black-75;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 143%;
  margin: 0;

  @media (min-width: $desktop-width) {
    font-size: 28px;
  }
}

.arrangement-sidebar__data {
  box-sizing: border-box;
  display: grid;
  gap: 32px;
  padding-bottom: 12px;
  padding-top: 12px;

  @media (min-width: $desktop-width) {
    padding-bottom: 40px;
    padding-top: 14px;
  }
}

.arrangement-sidebar__data_cancelable {
  padding-bottom: 24px;

  .arrangement-sidebar__cost-table-row_footer {
    padding-bottom: 0;
  }
}

.arrangement-sidebar__data-main {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 40px;
  padding-left: 12px;
  padding-right: 12px;

  @media (min-width: $desktop-width) {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.arrangement-sidebar__data-block {
  display: grid;
  gap: 14px;
}

.arrangement-sidebar__data-value {
  color: $color-black-75;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  margin: 0;
  word-break: break-all;
}

.arrangement-sidebar__data-header {
  box-sizing: border-box;
  color: $color-black-75;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin: 0;
}

.arrangement-sidebar__data-header_cost {
  padding: 0 12px;

  @media (min-width: $desktop-width) {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.arrangement-sidebar__cost-table-body {
  padding-left: 12px;
  padding-right: 12px;

  @media (min-width: $desktop-width) {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.arrangement-sidebar__cost-table-row {
  border-bottom: 1px solid $color-black-10;
  box-sizing: border-box;
  color: $color-black-75;
  display: grid;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  gap: 8px;
  grid-template-columns: 1fr auto;
  line-height: 143%;
  padding-bottom: 10px;
  padding-top: 10px;
}

.arrangement-sidebar__cost-table-row_header {
  background-color: $color-black-5;
  border-bottom: 1px solid $color-black-10;
  box-sizing: border-box;
  color: $color-black-30;
  font-family: $font-main;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
  padding: 8px 12px;
  text-transform: uppercase;

  @media (min-width: $desktop-width) {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.arrangement-sidebar__cost-table-row_footer {
  border: none;
  font-weight: 600;
  padding: 12px;
  text-transform: uppercase;

  @media (min-width: $desktop-width) {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.arrangement-sidebar__cost-table-cell {
  margin: 0;
}

.arrangement-sidebar__cancel-button {
  color: $color-theme-accent;
  margin: 0 auto;
  max-width: 336px;
  padding-bottom: 9px;
  padding-top: 9px;
  width: calc(100% - 24px);

  @media (min-width: $desktop-width) {
    width: 210px;
  }
}

.arrangement-sidebar__data-links {
  align-content: start;
  color: $color-black-75;
  display: grid;
  gap: 14px;
}

.arrangement-sidebar__data-link {
  align-items: center;
  color: $color-black-75;
  display: grid;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  gap: 16px;
  grid-auto-flow: column;
  justify-content: start;
  line-height: 120%;
  min-height: 21px;
  text-decoration: none;

  svg {
    height: 16px;
    width: 16px;
  }
}

.arrangement-sidebar__data-link-text {
  position: relative;
  text-transform: none;
  transition: text-decoration 1s;

  &::after {
    background-color: $color-black-75;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    transition: all $transition-time;
    width: 100%;
  }

  @media (min-width: $desktop-width) {
    &:hover {
      &::after {
        background-color: transparent;
      }
    }
  }
}

.arrangement-sidebar__data-list_bordered {
  padding-bottom: 28px;
  position: relative;

  &::after {
    background-color: $color-black-10;
    bottom: 8px;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.arrangement-sidebar__data-empty {
  color: $color-black-75;
  font-family: $font-main;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 130%;
  margin: 0;
  text-align: center;
}
