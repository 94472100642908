@import '../../../../assets/styles/variables.scss';

.table-filters {
  align-content: start;
  display: grid;
  height: calc(100vh - 52px);
  left: 0;
  opacity: 0;
  position: fixed;
  top: 53px;
  transition: opacity $transition-time ease;
  visibility: hidden;
  width: 100%;
  z-index: 100;

  @media (min-width: $laptop-width) {
    background: none;
    height: auto;
    opacity: 1;
    position: static;
    visibility: visible;
  }
}

.table-filters_shown-mobile {
  grid-template-rows: auto 1fr;
  opacity: 1;
  visibility: visible;
}

.table-filters__mobile-shadow {
  background-color: rgba($color: $color-black, $alpha: 0.5);
}

.table-filters__wrapper {
  background-color: $color-white;
  border-radius: 2px;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.16);
  display: grid;
  gap: 32px;
  padding: 16px 12px 24px;

  @media (min-width: $laptop-width) {
    border-radius: 0;
    box-shadow: none;
    gap: 20px;
    padding: 20px;
  }
}

.table-filters__section-filters-wrapper {
  align-content: start;
  display: grid;
  gap: 16px;

  @media (min-width: $laptop-width) {
    &:not(.table-filters__section-filters-wrapper_selects) {
      gap: 12px;
    }
  }
}

.table-filters__section-filters-wrapper_selects {
  gap: 20px;
}

.table-filters__quick-date {
  display: grid;
  gap: 4px;
  grid-auto-flow: column;
  justify-content: start;
}

.table-filters__apply-button {
  @media (min-width: $laptop-width) {
    display: none;
  }
}

.table-filters__section {
  align-content: start;
  display: grid;
  gap: 24px;

  @media (min-width: $laptop-width) {
    gap: 20px;
  }
}

.table-filters__section-title {
  color: $color-black-75;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  margin: 0;
}

.table-filters__section-input {
  @media (min-width: $laptop-width) {
    .input__field::placeholder {
      font-size: 12px;
    }
  }
}

.table-filters__section-inputs {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;
}
