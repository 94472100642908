@import '../../../../assets/styles/variables.scss';

.table {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  flex: 1;
  overflow: auto;
  padding-top: 52px;
  position: relative;
  width: 768px;

  @media (min-width: $tablet-width) {
    width: 100%;
  }

  @media (min-width: $laptop-width) {
    grid-template-rows: auto 1fr auto;
    height: 100vh;
    padding-top: 0;
  }
}

.table_loading {
  @media (min-width: $laptop-width) {
    .table__body {
      position: relative;
    }
  }
}

.table__loader {
  @media (max-width: $laptop-width - 1) {
    background: none;
    bottom: 0;
    height: auto;
    left: 0;
    top: auto;
    position: relative;
    transform: translate(0, 0);
    z-index: 9;

    .loader__wrapper {
      padding: 0;
    }
  }
}

.table__header {
  background-color: $color-black-5;
  border-top: 1px solid $color-black-10;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 600;
  grid-auto-flow: column;
  line-height: 133%;
  text-align: left;
  text-transform: uppercase;

  @media (min-width: $laptop-width) {
    position: sticky;
    top: 0;
  }
}

.table__row {
  align-items: center;
  background: none;
  border: none;
  border-bottom: 1px solid $color-black-10;
  box-sizing: border-box;
  color: $color-black-75;
  cursor: pointer;
  display: grid;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  gap: 1%;
  grid-template-columns: 8.7% 13.2% 11.2% 21% 16% 12.9% 10.7%;
  line-height: 143%;
  padding: 12px 16px;
  text-align: left;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: auto;
  }

  @media (min-width: $laptop-width) {
    grid-template-columns: 8% 9.8% 9.8% 22.6% 14.2% 14.6% 14.8%;
    padding-left: 12px;
    padding-right: 12px;
  }

  @media (min-width: $desktop-width) {
    grid-template-columns: 11% 11.5% 10.3% 18.4% 17.1% 14.2% 11.4%;
    padding-left: 32px;
    padding-right: 32px;
  }
}

.table__row_header {
  color: $color-black-30;
  cursor: default;
  font-size: 12px;
  font-weight: 600;
  line-height: 133%;
  padding-bottom: 10px;
  padding-top: 10px;
  text-transform: uppercase;
}

.table__row_canceled {
  .table__cell {
    color: $color-black-30;
  }
}

.table__row_warn {
  background-color: $color-rush;
}

.table__body {
  box-sizing: border-box;
  padding-bottom: 64px;
}

.table__cell {
  overflow: hidden;
  text-overflow: ellipsis;
}

.table__cell_return {
  display: grid;
  gap: 4px;
}

.table__cell-return-date {
  color: $color-black-50;
  font-size: 12px;
}

.table__footer {
  box-sizing: border-box;
  @media (min-width: $laptop-width) {
    align-self: end;
    background-color: $color-white;
    border-top: 1px solid $color-black-10;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    width: 100%;
  }
}

.table__footer-section {
  box-sizing: border-box;
  @media (min-width: $laptop-width) {
    border-left: 1px solid $color-black-10;
  }
}

.table__footer-section_pagination {
  width: 274px;
  @media (max-width: $laptop-width - 1) {
    display: none;
  }
}

.table__footer-section_search {
  padding-left: 4px;
  @media (max-width: $laptop-width - 1) {
    left: 0;
    position: fixed;
    top: 52px;
  }
}

.table__no-data {
  color: $color-black-75;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding: 40px;

  @media (min-width: $laptop-width) {
    text-align: center;
  }
}
