@import '../../../../assets/styles/variables.scss';

.signout {
  @media (min-width: $laptop-width) {
    align-items: center;
    background-color: $color-black-5;
    border-radius: 20px;
    box-sizing: border-box;
    color: $color-theme-light;
    display: grid;
    gap: 12px;
    grid-template-columns: auto 1fr auto;
    order: 1;
    padding: 10px 16px;
    width: 100%;
  }
}

.signout__user-name {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.signout__user-icon,
.signout__user-name {
  @media (max-width: $laptop-width - 1) {
    display: none;
  }
}

.signout__user-name-part {
  max-width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.signout__button {
  color: $color-theme-light;
}

.signout_long-name {
  @media (min-width: $laptop-width) {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.signout_shrink {
  @media (min-width: $laptop-width) {
    gap: 6px;
  }
}
