@import "../../../../assets/styles/variables.scss";

.pagination {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  gap: 36px;
  grid-template-columns: 1fr 90px;
  justify-items: end;
  padding: 14px 32px 14px 20px;
}

.pagination__numbers {
  align-items: center;
  box-sizing: border-box;
  color: $color-theme-accent;
  display: flex;
  font-family: $font-main;
  font-size: 14px;
  font-weight: 400;
  justify-items: end;
  line-height: 150%;
  text-align: left;
}

.pagination__buttons {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
}

.pagination__buttons-pair {
  align-items: center;
  box-sizing: border-box;
  display: flex;
}

.pagination__button {
  color: $color-theme-accent;

  &:disabled {
    color: $color-black-20;
  }
}

.pagination__button_previous {
  transform: rotate(90deg);
}

.pagination__button_next {
  transform: rotate(-90deg);
}

.pagination__button_last {
  transform: rotate(-180deg);
}
