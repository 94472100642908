@import "variables";

@keyframes fadeOut {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes showFromTop {
  0% {
    transform: translateY(-100vh);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes showFromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes showToRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}
