@import "../../../assets/styles/variables.scss";

.loader {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  max-width: 100vw;
  padding-bottom: 10px;
  width: 100%;
}

.loader__wrapper {
  display: grid;
  justify-items: center;
}

.loader__text {
  color: $color-black-20;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 120%;
  margin: 0;
}

.loader_position_right {
  height: 100%;
  justify-content: flex-end;
  padding: 0;
  position: absolute;
  z-index: 20;

  .loader__wrapper,
  .loader__spinner {
    height: 100%;
  }
}

.loader_align_left {
  justify-content: flex-start;
}

.loader_position_overlay {
  background-color: rgba($color: $color-black-5, $alpha: 0.8);
  height: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 20;
  width: 100%;

  .loader__wrapper {
    padding-bottom: 30vh;
  }
}
